import {InterCurrency} from "~/enums/siteEnum";

export const state = () => ({
  info: {},
  ipInfo: {}, // ip 相关信息
  siteUserInfo: {}, // 用户绑定角色的信息
  siteLoginUserInfo: null, // 用户注册之后登录的账号
  isSiteBindUser: false, // 是否只通过 player_id登录系统（游客登录）
  token: '',
  loginShow: false,
  uuid: '',
  currencyRound: 2,
  isUpdateUuid: false, // 是否更新uuid 更新不显示loading刷新商品
})

export const mutations = {
  BIND_PLAYER_INFO(state, info){
    const uid =  info.uid || info.player_id || info.id
    state.siteLoginUserInfo = {
      ...info,
      ...state.siteLoginUserInfo,
      player: info,
      player_id: info.id
    }
    state.siteUserInfo = {
      ...info,
      uid: info.uid || info.player_id || info.id
    }
    state.uuid = uid
    state.isUpdateUuid = true
  },
  UPDATE_UUID(state, id){
    state.uuid = id
    state.isUpdateUuid = true
  },
  SET_SITE_BIND_USER(state, bol) {
    state.isSiteBindUser = bol
  },
  SET_SITE_LOGIN_USER(state, info) {
    state.siteLoginUserInfo = info
    if(info?.player){
      const id = info.uid || info.player_id
      state.siteUserInfo = {
        ...info.player,
        uid: info.uid || info.player_id,
      }
      state.uuid = id
    }
  },
  CLEAR_SITE_LOGIN_USER(state){
    state.siteLoginUserInfo = null
    state.siteUserInfo = null
    state.customerInfo = {}
  },
  CLEAR_SITE_LOGIN_USER_MODAL(state){
    state.siteLoginUserInfo = null
    state.siteUserInfo = null
    state.loginShow = false
  },
  SET_USER(state, info) {
    state.info = info
  },
  SET_SITEUSER(state, info) {
    state.siteUserInfo = info
    if (state.siteLoginUserInfo) {
      state.siteLoginUserInfo.player_id = info?.uid
    }

  },
  SET_TOKEN(state,token){
    state.token = token
  },
  SET_LOGINSHOW(state, flag) {
    state.loginShow = flag
  },

  CLEAR_LOGIN_STATE(){
    state.user = null
    state.token = ''
  },
  SET_IPINFO (state, info) {
    state.currencyRound = InterCurrency.includes(info.currency) ? 0 : 2
    state.ipInfo = info;
  }
}
export const actions ={
   async LOGINOUT ({ commit }){
     await new Promise((resolve)=>{
       resolve(commit('CLEAR_LOGIN_STATE'))
     })
   },
  nuxtServerInit({ commit }, ctx) {
    // const { $utils } = ctx
    // todo   刷新时从cookie中取信息出来保存
    // const token = $utils.getToken(ctx);
    // const user = $utils.getAuthUser(ctx);
    // commit('SET_TOKEN', token);
    // commit('SET_USER', user);
  },
}
